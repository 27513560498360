<template>
  <section class="has-error" v-if="err">{{ err }}</section>
  <section v-else>
    <iframe></iframe>
  </section>
</template>

<script>
import AuthService from "@/services/auth";
const qs = ($vm) => {
  let params = null;
  try {
    if ($vm.$utils.gup("_cdim")) {
      params = JSON.parse(localStorage.getItem("_cdim"));
    }
  } catch (error) {
    console.log(error);
  }
  if (!params) {
    console.log(
      "TODO: display a message to the user that the configuration is missing"
    );
  }
  return params;
};
const clone = (obj) => {
  try {
    return structuredClone(obj);
  } catch (error) {
    return JSON.parse(JSON.stringify(obj));
  }
};
export default {
  name: "CustomPage",
  data() {
    return {
      err: "",
      params: {},
      isMounted: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isRulesReady() {
      return this.$store.getters["user/isRulesReady"];
    },
    isEquipmentReady() {
      return this.$store.getters.isReady;
    },
    isReady() {
      return (
        this.isMounted &&
        this.isEquipmentReady &&
        this.contract &&
        this.isRulesReady
      );
    }
  },
  watch: {
    contract: {
      immediate: true,
      handler(n, o) {
        if (!o && n && !this.isEquipmentReady) {
          this.fetchScreens();
          this.fetchEquipment();
        }
      }
    },
    isReady: {
      immediate: true,
      handler(n, o) {
        if (!o && n) {
          // console.log("CustomPage READY");
          this.build();
        }
      }
    }
  },
  methods: {
    async fetchScreens() {
      if (!this.contract) return;
      this.$store.dispatch("dashboard/fetchScreens", {
        contract_id: this.contract.id
      });
    },
    async fetchEquipment() {
      if (!this.contract) return;
      this.$root.$store.dispatch("fetchEquipmentList");
    },
    async postMessage(command, data) {
      if (!this.iframeContentWindow) return;
      this.iframeContentWindow.postMessage({
        hi_msg: command,
        value: JSON.parse(JSON.stringify(data))
      });
    },
    getStoreState() {
      let entry = {
        config: this.$root.config,
        root: clone(this.$store._modules.root.state),
        modules: {}
      };
      for (var name in this.$store._modules.root._children) {
        if (name == "scripts") continue;
        entry.modules[name] = clone(
          this.$store._modules.root._children[name].state
        );
      }
      return entry;
    },
    onMessageArrived(e) {
      if (e.data.hi_msg == "content-view:ready") {
        // console.log("content-view:ready");
        this.postMessage("custom-page:sync", this.getStoreState());
      }
    },
    iframeLoaded() {
      window.addEventListener("message", this.onMessageArrived.bind(this));
    },
    build() {
      try {
        this.params = qs(this);
        if (this.params?.wrapper?.html_body) {
          // inject iframe content (maz 2022)
          var doc = this.$el.firstChild.contentWindow.document;
          doc.open();
          // body
          doc.write(this.params.wrapper.html_body);
          // compatible charset
          const metaCharSet = doc.createElement("meta");
          metaCharSet.charset = "UTF-8";
          doc.head.appendChild(metaCharSet);
          // viewport
          const metaViewPort = doc.createElement("meta");
          metaViewPort.name = "viewport";
          metaViewPort.content = "width=device-width, initial-scale=1.0";
          doc.head.appendChild(metaViewPort);
          // custom style
          if (this.params?.wrapper?.style_sheet) {
            const style = doc.createElement("style");
            style.type = "text/css";
            style.innerHTML = this.params.wrapper.style_sheet;
            doc.head.appendChild(style);
          }
          // if there is an iframe in the provided body, inject it
          const iframe = doc.querySelector("iframe");
          if (iframe) {
            this.iframeContentWindow = iframe.contentWindow;
            iframe.addEventListener("load", this.iframeLoaded.bind(this));
            iframe.src = `/content_view/${document.location.search}`;
            iframe.style.backgroundColor = "transparent";
          }
          doc.close();
        } else {
          this.err = "Invalid content source";
        }
      } catch (e) {
        this.err = e.toString();
      }
    }
  },
  mounted() {
    this.isMounted = true;
  },
  beforeDestroy() {
    // console.log("CustomPage:Destroyed");
    window.removeEventListener("message", this.onMessageArrived);
    if (this.iframeContentWindow) {
      this.iframeContentWindow.postMessage({
        hi_msg: "custom-page:destroy"
      });
      this.iframeContentWindow.document.removeChild(
        this.iframeContentWindow.document.documentElement
      );
      this.$el.innerHTML = "<iframe></iframe>";
      this.$parent.$el.innerHTML = "";
    }
  },
  created() {
    if (!this.user) {
      let auth = new AuthService();
      if (auth.access_token()) {
        this.$store
          .dispatch("user/login", {keep_alive: auth.keepAlive()})
          .catch(() => {
            // TODO: Display a message to the user that the session has expired
            this.$router.push("/");
          });
      }
    }
  }
};
</script>

<style scoped>
section.has-error {
  padding: 20px;
  text-align: center;
  color: brown;
}
iframe {
  min-width: 100%;
  min-height: 100dvh;
  margin: 0 0 -5px 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}
</style>
